import React, { useEffect, useState } from 'react'
import Requests from '../scripts/requests'
import { useSelector } from 'react-redux'

import '../css/FantaPokemon.scss'
import PokemonTeamCard from '../components/PokemonTeamCard'
import CircleLoading from '../components/CircleLoading'
import { Team } from '../scripts/types'



function FantaPokemon() {

  const accessToken = useSelector((state: any) => state.user.accessToken)
  const [gameStatus, setGameStatus] = useState<boolean>()
  const [teams, setTeams] = useState<Team[] | null>(null)

  const [statusInterval, setStatusInterval] = useState<NodeJS.Timer | null>(null)

  useEffect(() => {
    if (accessToken && !statusInterval) {
      Requests.getTeams(accessToken)
        .then(res => {
          console.log(res);
          setTeams(res)
        })
      Requests.getFantaPokemonStatus(accessToken)
        .then(res => {
          setGameStatus(res['gameStatus'])

          let interval = setInterval(() => {
            Requests.getFantaPokemonStatus(accessToken)
              .then(res => {
                setGameStatus(res['gameStatus'])
              })
          }, 30 * 1000)

          setStatusInterval(interval)

        })

      return () => {
        if (statusInterval)
          clearInterval(statusInterval)
      }
    }
  }, [accessToken, statusInterval])

  return (
    <div className='content-wrapper'>
      {!gameStatus ?
        <div className="no-game">
          <h3>Il gioco non è iniziato.</h3>
        </div>
        :
        <div className="pokemon-cards">
          {
            teams ?
              teams.map((el: Team, idx: number) => {
                return <PokemonTeamCard cardNo={idx+1} team={el}/>
              })
              :
              <CircleLoading color='#fff' />
          }
        </div>

      }
      {/*<iframe title='FantaPoké' width={1280} height={720} src='/static/fantapoke.html' />*/}
      {/*<PokemonMap />*/}
    </div>
  )
}

export default FantaPokemon