import React, { useEffect, useState } from 'react'
import '../css/components/LeaderboardFantapoke.scss'
import Requests from '../scripts/requests'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import PokemonScoresOverlay from './PokemonScoresOverlay'
import { useSelector } from 'react-redux'

interface FantapokeData {
    position: number
    username: string
    user_id: string
    points: number
}


function LeaderboardFantapoke() {

    const accessToken = useSelector((state: any) => state.user.accessToken)

    const [leaderboard, setLeaderboard] = useState<FantapokeData[]>([])
    const [search, setSearch] = useState<string>("");

    const [scoresID, setScoresID] = useState<string>("")
    const [scoreUsername, setScoreUsername] = useState<string>("")

    useEffect(() => {
        Requests.getPokemonScores()
            .then(res => {
                const array: FantapokeData[] = []
                res.forEach((el: FantapokeData, idx: number) => {
                    array.push({
                        "position": idx + 1,
                        "user_id": el.user_id,
                        "username": el.username,
                        "points": el.points
                    })
                });

                setLeaderboard(array)
            })
            .catch(err => null)
    }, [])


    const inputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setSearch(value)
    }

    const goBack = ()=>{
        setScoresID("")
        setScoreUsername("")
    }

    return (
        <div className='fantapoke-leaderboard-wrapper'>
            <img alt='fantapoke-leaderboard_title' src='/img/LEADERBOARD.png' className='fantapoke-leaderboard-title' />
            <div className="fantapoke-search-box">
                <input type="text" className='search-input' onChange={inputChanged} />
                <FontAwesomeIcon icon={faSearch} className='search-icon' />
            </div>

            {scoresID !== "" &&
                <PokemonScoresOverlay
                    access_token={accessToken}
                    username={scoreUsername}
                    userID={scoresID}
                    goBack={goBack}
                />
            }

            <ul id="fantapoke-leaderboard">
                {
                    search === "" ?
                        leaderboard.map((e: FantapokeData, idx: number) => {
                            return <li className='fantapoke-leaderboard-el' key={idx}>
                                <p>{e.position}. {e.username}</p>
                                <a className='fantapoke-leaderboard-scores' onClick={() => {
                                    setScoresID(e.user_id)
                                    setScoreUsername(e.username)    
                                }}> apri punteggi</a>
                                <span>
                                    <p>{Number.isInteger(e.points) ? e.points : e.points.toFixed(1)} punti</p>

                                </span>
                            </li>
                        })
                        :
                        leaderboard.filter((e: FantapokeData) => e.username.includes(search!)).map((e: FantapokeData, idx: number) => {
                            return <li className='fantapoke-leaderboard-el' key={idx}>
                                <p>{e.position}. {e.username}</p>
                                <span>
                                    <p>{Number.isInteger(e.points) ? e.points : e.points.toFixed(1)} punti</p>
                                </span>
                            </li>
                        })}
            </ul>
        </div>
    )
}

export default LeaderboardFantapoke