import React, { useEffect, useRef, useState } from 'react'

import "../css/PokemonScores.scss"
import { AdminRequests } from '../scripts/admin_requests'
import { useCookies } from 'react-cookie'
import { Pokemon } from '../scripts/types'
import PokemonTypeBadge from '../components/PokemonTypeBadge'
import CircleLoading from '../components/CircleLoading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'

interface Score {
    in_team?: boolean
    id: number
    pokemon_id: number
    alive: boolean
    died: boolean
    resurrected: boolean
    win: number
    lost: number
    multiplier: number
    total: number
}

interface ScoreConstants {
    alive: number
    died: number
    resurrected: number
    win: number
    lost: number

}

function PokemonScores() {

    const [assignment, setAssignment] = useState<boolean>()
    const [saving, setSaving] = useState<boolean>()
    const [result, setResult] = useState<Score[]>([])
    const [enableSend, setEnableSend] = useState<boolean>(false)

    const [cookies, setCookie, removeCookie] = useCookies();
    const [pokemonList, setPokemonList] = useState<Pokemon[]>([])
    const [constants, setConstants] = useState<ScoreConstants | null>(null)
    const [scores, setScores] = useState<Score[]>([
        {
            id: 0,
            pokemon_id: -1,
            alive: false,
            died: false,
            resurrected: false,
            win: 0,
            lost: 0,
            multiplier: 1,
            total: 0
        }
    ])

    // Funzione per aggiungere un nuovo elemento
    const addScore = () => {
        setScores((scores) => [
            ...scores,
            {
                id: scores.length + 1,
                pokemon_id: -1,
                alive: false,
                died: false,
                resurrected: false,
                win: 0,
                lost: 0,
                multiplier: 1,
                total: 0
            },
        ]);
    };

    const sendScores = () => {
        console.log(scores);

        let confim = window.confirm("Sei sicuro di voler salvare i punteggi?")
        if (!confim) return;

        setAssignment(true)
        setSaving(true)
        let accessToken = cookies['access_token']
        if (accessToken) {
            AdminRequests.setPokemonScores(accessToken, scores)
                .then(res => {
                    console.log(res);
                    setResult(res)
                })
                .catch(err => {
                    console.log(err);
                    setResult([])
                })
                .finally(() => {
                    setSaving(false)
                    //setAssignment(false)
                })
        }

    }

    const updatePokemonID = (id: number, pokemon_id: number) => {
        function changePokemonID(item: Score) {
            item.pokemon_id = pokemon_id
            return item
        }

        setScores((prev) =>
            prev.map((item) =>
                item.id === id ? changePokemonID(item) : item
            )
        );
    }

    const updateScore = (score: Score) => {
        if (constants) {
            score.total = 0
            if (score.alive)
                score.total += constants.alive

            if (score.died)
                score.total += constants.died

            if (score.resurrected)
                score.total += constants.resurrected

            if (score.win !== 0)
                score.total += constants.win * score.win

            if (score.lost !== 0)
                score.total += constants.lost * score.lost

            score.total = score.total * score.multiplier
            setScores((prev) =>
                prev.map((item) =>
                    item.id === score.id ? score : item
                )
            );
        }
    }

    const removeScore = (id: number) => {
        setScores((prev) => prev.filter((item) => item.id !== id));
    }

    useEffect(() => {
        if (cookies['access_token']) {
            let accessToken = cookies['access_token']
            AdminRequests.getPokemonScoresConstants(accessToken)
                .then(res => {
                    setConstants(res)
                })
                .catch(err => console.log(err))

            AdminRequests.getAllPokemon(accessToken, false)
                .then(res => {
                    setPokemonList(res)
                })
                .catch(err => console.log(err))
        }
    }, [])

    useEffect(() => {
        let valid = true
        scores.forEach((el: Score) => {
            if (el.pokemon_id === -1) {
                valid = false
                return
            }

            if (el.win < 0 || el.lost < 0 || el.multiplier < 0) {
                valid = false
                return
            }
        })


        setEnableSend(valid)
    }, [scores])

    return assignment ?
        saving ?
            <div className='pokemon-scores-wrapper'>
                <CircleLoading color='#fff' />
            </div>
            :
            <div className="results-list">
                {
                    result.length === 0 ?
                        <p>Nessun punteggio pokemon salvato</p>
                        :

                        pokemonList.filter((el: Pokemon) => result.some(e => e.pokemon_id === el.id)).map((el: Pokemon) => {
                            let matched = result.find(e => e.pokemon_id === el.id)

                            return (
                                <div className="pokemon-result" key={el.id}>
                                    {matched?.in_team === false && (
                                        <p className='not-in-team'>NON SCHIERATO</p>
                                    )}
                                    <p>POKEMON ID: {el.id}</p>
                                    <p>POKEMON NAME: {el.name}</p>
                                    <p>TYPES: {el.types}</p>
                                </div>
                            )
                        })
                }

                <div className="results-buttons">
                    <button onClick={() => {
                        setResult([])
                        setAssignment(false)
                        setSaving(false)
                    }}>
                        INDIETRO
                    </button>
                </div>

            </div>
        :
        constants && (
            <div className='pokemon-scores-wrapper'>
                {
                    scores.map((e: Score) => {
                        return (
                            <PokemonScoreForm
                                key={e.id}
                                pokemonList={pokemonList}
                                score={e}
                                updateState={updateScore}
                                removeScore={removeScore}
                                updatePokemonID={updatePokemonID}
                            />
                        )
                    })
                }

                <div className="pokemon-buttons">
                    <button onClick={addScore}>AGGIUNGI</button>
                    {enableSend && <button onClick={sendScores}>CONFERMA</button>}
                </div>

            </div>
        )
}



interface PokemonScoreFormProps {
    pokemonList: Pokemon[]
    score: Score,
    updateState: any
    removeScore: any
    updatePokemonID: any
}

function PokemonScoreForm({ pokemonList, score, updateState, removeScore, updatePokemonID }: PokemonScoreFormProps) {

    const [pokemon, setPokemon] = useState<Pokemon | null>()
    const [data, setData] = useState<Score>({
        "id": score.id,
        "pokemon_id": score.pokemon_id,
        "win": 0,
        "lost": 0,
        "resurrected": false,
        "alive": false,
        "died": false,
        "multiplier": 1,
        "total": 0
    })

    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        //console.log(data);
        updateState(data)
    }, [data])

    useEffect(() => {
        if (pokemon) {
            updatePokemonID(data.id, pokemon.id)
        }
    }, [pokemon])

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked, value } = event.target;
        //console.log(name, checked, value);

        setData((prevCheckboxes) => ({
            ...prevCheckboxes,
            [name]: checked,
        }));

    };

    const handleInputStringChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, checked } = event.target;
        //console.log(name, value, checked);

        setData((prev) => (
            {
                ...prev,
                [name]: value
            })
        )
    }


    const inputPokemonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value)
    }

    return (
        <form className="pokemon-scores-form">

            <a className="pokemon-scores-form-remove-btn">
                <FontAwesomeIcon icon={faClose} onClick={() => { removeScore(score.id) }} />
            </a>

            <div className="pokemon-choose">
                <div
                    className="pokemon-image"
                    style={{ backgroundImage: 'url(' + (pokemon ? pokemon?.image : '/img/ask.png') + ')' }}
                ></div>

                <input type='string' placeholder='nome pokemon...' onChange={inputPokemonChange} />

                <div className='pokemon-choose-list'>
                    {searchTerm.length > 0 &&
                        pokemonList.filter(el => el.name.toLowerCase().includes(searchTerm.toLowerCase())).map((el: Pokemon) => {
                            return (
                                <div key={el.id} className='pokemon-data' onClick={() => {
                                    setPokemon(el)
                                    setSearchTerm("")
                                }}>
                                    <p>{el.id} - {el.name}</p>
                                    <div className='pokemon-data-types'>
                                        {el.types.toLowerCase().split(",").map((el: string, idx: number) => {
                                            return <PokemonTypeBadge type={el} key={idx} />
                                        })}
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>
            </div>

            <div className="pokemon-choose-body">
                {pokemon &&
                    <div className="pokemon-choose-info">
                        <p className="pokemon-name">{pokemon.name}</p>
                        <div className="pokemon-choose-badges">
                            {
                                pokemon.types.split(",").map((el: string, idx: number) => {
                                    return (
                                        <PokemonTypeBadge type={el} key={idx} />
                                    )
                                })
                            }
                        </div>
                    </div>
                }
                <ul className="pokemon-scores">
                    <li>
                        <p>WIN</p>
                        <input
                            type="number"
                            min={0}
                            name="win"
                            value={data.win}
                            onChange={handleInputStringChange}
                        />
                    </li>
                    <li>
                        <p>LOST</p>
                        <input
                            type="number"
                            min={0}
                            name="lost"
                            value={data.lost}
                            onChange={handleInputStringChange}
                        />
                    </li>
                    <li>
                        <p>RISORTO</p>
                        <input
                            type="checkbox"
                            name="resurrected"
                            checked={data.resurrected}
                            onChange={handleCheckboxChange}
                        />
                    </li>
                    <li>
                        <p>VIVO</p>
                        <input
                            type="checkbox"
                            name="alive"
                            checked={data.alive}
                            onChange={handleCheckboxChange}
                        />
                    </li>
                    <li>
                        <p>MORTO</p>
                        <input
                            type="checkbox"
                            name="died"
                            checked={data.died}
                            onChange={handleCheckboxChange}
                        />
                    </li>

                    <li className='multiplier'>
                        <p>MOLTIPLICATORE</p>
                        <input
                            type="number"
                            min={1}
                            name="multiplier"
                            defaultValue={1.0}
                            onChange={handleInputStringChange}
                        />
                    </li>
                </ul>
            </div>


            <div className="pokemon-score">
                <p>{data.total} punti</p>
            </div>

            <div className="pokemon-actions"></div>
        </form >
    )
}

export default PokemonScores