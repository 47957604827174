import React, { useEffect, useState } from 'react'
import Requests from '../scripts/requests'

import "../css/PokemonScoresOverlay.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import PokemonTypeBadge from './PokemonTypeBadge'
import CircleLoading from './CircleLoading'

interface PokemonScoresOverlayProps {
    access_token: string
    userID: string
    username: string
    goBack: any
}

function PokemonScoresOverlay({ access_token, username, userID, goBack }: PokemonScoresOverlayProps) {

    const [pokemons, setPokemons] = useState<[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true)
        Requests.getPokemonUserScores(access_token, userID)
            .then(res => setPokemons(res))
            .catch(err => setPokemons([]))
            .finally(()=>{
                setLoading(false)
            })
    }, [access_token, userID])


    useEffect(()=>{
        document.body.style.overflow = 'hidden';
        return ()=>{
            document.body.style.overflow = 'auto';
        }
    },[])

    return (
        <div className='pokemon-scores-overlay'>
            <FontAwesomeIcon icon={faClose} id='close-btn' onClick={()=>goBack()} color='red'/>
            <div id="scores-list">
                <div id='scores-title'>Punteggi pokemon <p>{username}</p></div>

                {
                    loading ?
                    <CircleLoading color='black'/>
                    :
                    pokemons.map(((el: any, idx) => {
                    return(
                        <div className='score-element' key={idx}>
                            <div className="score-element-info">
                                <img src={el['image']} alt='pokemon_image' loading='lazy'/>
                                <p>{el['pokemon']}</p>
                            </div>
                            {"type" in el &&
                            <div className="score-element-types">
                                {el['types'].split(",").map((el: string) => {
                                    return <PokemonTypeBadge type={el} />
                                })}
                            </div>
                            }
                            <p>{el['points']} punti</p>
                        </div>
                    )
                }))}
            </div>
        </div>
    )
}

export default PokemonScoresOverlay